.buttons{
    display: block;
    width: fit-content;
    transition: 100ms linear;

    .button{
        font-size: 1rem;
        font-family: 'Rubik', sans-serif;
        font-weight: 700;
        color: #fff;

        background-color: #4066E0;
        border-radius: 0.625rem;
        padding: 1.065rem 3.20rem;
        height: auto;
    }

    &__boxShadow{
        box-shadow: rgba(64, 102, 224, 1) 0px 15px 40px -7px;
    }
}