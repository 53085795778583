@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/rez');

$primary-font: 'Montserrat', sans-serif;
$secundary-font: 'Rubik', sans-serif;
$terceary-font: 'REZ', sans-serif;

h1{
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.59463rem;

    b{
        font-weight: 700;
    }
}

h2{
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.125rem;

    b{
        font-weight: 700;
    }
}

p{
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;

    b{
        font-weight: 700;
    }
}

a{
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}
