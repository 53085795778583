footer{
    color: #0B132A;
    padding: 2.6rem 1rem 2.6rem 1rem !important;
    position: relative;

    .empresa{
        &__logo{

            img{
                margin-right: 0.35rem;
            }

            span{
                font-family: 'Rubik', sans-serif;
                font-size: 1.68306rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            @media screen and (max-width: 768px){
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        p{
            font-size: 0.875rem;

            @media screen and (max-width: 768px){
                text-align: center;
                font-size: 0.675rem;
            }
        }
    }

    .social{
        &__redes{
            min-height: 50px;

            &-item{
                display: flex;
                margin-left: 1.35rem;

                a{
                    color: #0B132A;

                    &:hover{
                        color: #4066E0;
                    }
                }

                @media screen and (max-width: 768px){
                    margin-left: 0px;
                    width: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &__links{
            a{
                color: #0B132A;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                margin-left: 2rem;

                &:hover{
                    color: #4066E0;
                }

                @media screen and (max-width: 768px){
                    margin-left: 0px;
                }
            }

            @media screen and (max-width: 920px){
                flex-direction: column-reverse;
                align-items: flex-end !important;
            }

            @media screen and (max-width: 768px){
                align-items: center !important;
            }
        }
    }

    @media screen and (max-width: 768px){
        .columns{
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column-reverse;
            align-items: center;
        }
    }
}
