.banner{
    background:
        // linear-gradient(
        //   180deg,
        //   rgba(0, 0, 0, 0.00) -13.21%, #000 100%
        // ),
        url('../../assets/backgrounds/bgBanner.png'), no-repeat;
    min-height: 47.25rem;
    background-position: left top;
    background-size: cover;

    h1{
        margin-bottom: 1.8rem;
    }

    p{
        margin-bottom: 1.8rem;
        max-width: 85%;
    }

    a{
        display: block;
        width: fit-content;
    }

    @media screen and (max-width: 768px){
        min-height: 42rem;
        background:
            linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.00) -13.21%, #000 100%
            ),
            url('../../assets/backgrounds/bgBanner.png'), no-repeat;

        .column{
            padding: 0px 15px;
        }
    }

    @media screen and (max-width: 920px){
        .is-three-fifths{
            display: none;
        }
    }
}

.quemSomos{
    background-color: #131313;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .bd-content{
        max-width: 950px;

        .columns{
            margin: 0px;

            p{
                line-height: 1.875rem;
            }

            img{
                object-fit: cover;
                width: 100%;
                min-width: 215px;
                max-width: 215px;
                min-height: 195px;
                max-height: 195px;
                border-radius: 1.9375rem;

                @media screen and (max-width: 1024px){
                    min-width: 100%;
                }

                @media screen and (max-width: 768px){
                    min-width: 215px;
                }
            }

            span{
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.875rem;
                text-align: center;

                @media screen and (max-width: 768px){
                    width: 100%;
                }
            }

            @media screen and (max-width: 768px){
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }

    &__descricao{
        max-width: 21.8125rem;
        margin: 0 auto;

        &--item{
            font-size: 0.875rem;
        }

        svg{
            margin-right: 0.65rem;
        }
    }

    @media screen and (max-width: 768px){
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.faleConosco{
    background-color: #131313;
    padding-top: 3rem;
    padding-bottom: 3rem;

    &__form{
        width: 100%;
    }
}
