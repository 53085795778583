.whatsAppButton{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 768px){
        width: 45px;
        height: 45px;
    }
}

.show{
    position: fixed;
    bottom: 30px;
    right: 30px;

    @media screen and (max-width: 768px){
        bottom: 10px;
        right: 10px;
    }
}

.hide{
    position: absolute;
    top: -85px;
    right: 30px;

    @media screen and (max-width: 768px){
        position: fixed;
        top: auto;
        bottom: 10px;
        right: 10px;
    }
}
